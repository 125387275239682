import React from 'react';
import ReactDOM from 'react-dom';
import reportWebVitals from './reportWebVitals';



import App from './App';


const root = ReactDOM.createRoot(document.getElementById('root'));


    root.render(
      <React.StrictMode>
        

        <App></App>



      </React.StrictMode>
    )


reportWebVitals();
